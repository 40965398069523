var bg;
export const Faqs = (props) => {
  if(localStorage.getItem('lang')==='th'){
     bg = 'faqs_th';
 }else{
    bg ='faqs';
 }
    return (
      <div id='faqs' className='text-center'>
            <div className='container'>
            <div className='section-title'>
            <h2>{props.data ? props.data.head : 'loading...'}</h2>
            {/* <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
              dapibus leonec.
            </p> */}
          </div>
              <div className='row'>
                <div className={bg}>
                
            </div>
      </div>
      </div>
      </div>
    )
  }
  