import {useState,useEffect} from 'react'
var bg;
export const Roadmap = (props) => {
const [check,setcheck] = useState(false)
  if (localStorage.getItem("lang") === "th") {
    bg = "roadmap_th";
  } else {
    bg = "roadmap";
  }

  useEffect(() => {
    console.log(props.data);
    if(props.data !== undefined){
      setcheck(true)
    }
  }, [props.data])



  return (
    <div id="roadmap" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>{props.data ? props.data.head : "loading..."}</h2>
          {/* <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit duis sed
              dapibus leonec.
            </p> */}
        </div>
        <div className="row">
          <div className={bg}>
            {/* <h1>
                    {props.data ? props.data.title : 'Loading'}
                    <span></span>
                  </h1> */}
            {/* <p>{props.data ? props.data.paragraph : 'Loading'}</p> */}
          </div>
        </div>
        <div className='col-sm-6 col-md-4 col-lg-4'  style={{display:'flex', flexDirection:'column', alignItems:'center',justifyContent: 'center',marginBottom:'30px'}}>
      <div className="card  " style={{display:'flex', flexDirection:'column', alignItems:'center',justifyContent: 'center',maxWidth: '40rem', border:'1px solid #4d0000', color:'white',borderRadius:'5px',backgroundColor:'	#660000',textAlign:'left'}}>
  <div className="card-header h2" style={{backgroundColor:'#660000'}}>Q2 2021</div>
  <div className="card-body" style={{backgroundColor:'#990000',borderBottomRightRadius:'5px',borderBottomLeftRadius:'5px' }}>
  {/* <h5 className="card-title" style={{marginTop:'0px'}}>Primary card title</h5> */}
    <ul className='list-style col-lg-12 col-sm-12 col-xs-12 roadmap-text h4' style={{margin:'0',height:'500px'}} >{check
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : 'loading'}</ul>
    

  </div>
</div>
</div>
<div className='col-sm-6 col-md-4 col-lg-4' style={{display:'flex', flexDirection:'column', alignItems:'center',justifyContent: 'center',marginBottom:'30px'}}>
<div className="card  " style={{display:'flex', flexDirection:'column', alignItems:'center',justifyContent: 'center',maxWidth: '40rem', border:'1px solid #4d0000', color:'white',borderRadius:'5px',backgroundColor:'	#660000',textAlign:'left'}}>
  <div className="card-header h2" style={{backgroundColor:'#660000'}}>Q3-Q4 2021</div>
  <div className="card-body" style={{backgroundColor:'#990000',borderBottomRightRadius:'5px',borderBottomLeftRadius:'5px' }}>
    <ul className="list-style col-lg-12 col-sm-12 col-xs-12 roadmap-last h4" style={{margin:'0',height:'500px'}}>{check
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : 'loading'}</ul>
  </div>
</div>
</div>
<div className='col-sm-6 col-md-4 col-lg-4' style={{display:'flex', flexDirection:'column', alignItems:'center',justifyContent: 'center',marginBottom:'30px'}}>
<div className="card  " style={{display:'flex', flexDirection:'column', alignItems:'center',justifyContent: 'center',maxWidth: '40rem', border:'1px solid #4d0000', color:'white',borderRadius:'5px',backgroundColor:'	#660000',textAlign:'left'}}>
  <div className="card-header h2" style={{backgroundColor:'#660000'}}>Q1-Q2 2022</div>
  <div className="card-body" style={{backgroundColor:'#990000',borderBottomRightRadius:'5px',borderBottomLeftRadius:'5px' }}>
    <ul className="list-style col-lg-12 col-sm-12 col-xs-12 roadmap-last h4" style={{margin:'0',height:'500px'}}>{check
                      ? props.data.Why3.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : 'loading'}</ul>
  </div>
</div>
</div>
<div className='col-sm-6 col-md-4 col-lg-4' style={{display:'flex', flexDirection:'column', alignItems:'center',justifyContent: 'center',marginBottom:'30px'}}>
<div className="card  " style={{display:'flex', flexDirection:'column', alignItems:'center',justifyContent: 'center',maxWidth: '40rem', border:'1px solid #4d0000', color:'white',borderRadius:'5px',backgroundColor:'	#660000',textAlign:'left'}}>
  <div className="card-header h2" style={{backgroundColor:'#660000'}}>Q3-Q4 2022</div>
  <div className="card-body" style={{backgroundColor:'#990000',borderBottomRightRadius:'5px',borderBottomLeftRadius:'5px' }}>
    <ul className="list-style col-lg-12 col-sm-12 col-xs-12 roadmap-last h4" style={{margin:'0',height:'500px'}}>{check
                      ? props.data.Why4.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : 'loading'}</ul>
  </div>
</div>
</div>

      </div>
    </div>
  );
};
