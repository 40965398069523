import { BrowserRouter, Link, Route, Switch } from "react-router-dom";
import Docs from "./docs";
import { useState } from "react"
import Axios from 'axios'



export const Navigation = (props) => {

  const [newaddress, setaddress] = useState("");
  const addAddress = () => {
    Axios.post('http://localhost/api/php/vsnt_api.php', {
      address: newaddress
    }).then((response) => {
      if (response.status == 0) {
        alert("Your wallet address send success")
      } else {
        alert("Your wallet address send invalid")
      }
    })


  }


  return (

    <div className='container'>
      <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
        <div className='navbar-header' style={{ display: 'flex', flexDirection: 'row', maxWidth: '540px' }}>

          <div >
            <a className='navbar-brand page-scroll' href='#page-top'>
              <h2>VSNT</h2>
            </a>
            <form style={{}}>
              <div style={{ display: 'flex', flexDirection: 'row', margin: '5px 0 0 100px' }}>
                <div className="form-group" style={{ marginRight: '1rem' }}>
                  <input
                    type="text"
                    name="wallet"
                    id="wallet"
                    className="form-control"
                    placeholder="Add your wallet ID of VSNT (valid 21/06/2021)"
                    style={{ maxWidth: '360px', fontSize: '8px' }}
                    onChange={(event) => {
                      setaddress(event.target.value)
                    }}
                  />
                </div>
                <button type="submit" className="btn btn-primary" style={{ maxHeight: '40px' }} onClick={addAddress}>Send</button>
              </div>
            </form>
          </div>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1' style={{ right: '0', position: 'absolute', marginRight: '40px' }}
          >


            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>


        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
            <li>
              <a href='#features' className='page-scroll'>
                {props.data ? props.data.feat : 'Loading'}
              </a>
            </li>
            <li>
              <a href='#about' className='page-scroll'>
                {props.data ? props.data.about : 'Loading'}
              </a>
            </li>
            <li>
              <a href='#concept' className='page-scroll'>
                {props.data ? props.data.concept : 'Loading'}
              </a>
            </li>
            <li>
              <a href='#tokenomic' className='page-scroll'>
                {props.data ? props.data.token : 'Loading'}
              </a>
            </li>
            <li>
              <a href='#service' className='page-scroll'>
                {props.data ? props.data.service : 'Loading'}
              </a>
            </li>
            <li>
              <a href='#roadmap' className='page-scroll'>
                {props.data ? props.data.road : 'Loading'}
              </a>
            </li>
            <li>
              <a href='#faqs' className='page-scroll'>
                {props.data ? props.data.faq : 'Loading'}
              </a>
            </li>
            {/* <li>
                <a href='#portfolio' className='page-scroll'>
                  {props.data ? props.data.partner : 'Loading'}
                </a>
              </li> */}
            {/* <li>
                <a href='#media' className='page-scroll'>
                  {props.data ? props.data.media : 'Loading'}
                </a>
              </li> */}
            {/* <li>
                <a href='#team' className='page-scroll'>
                  {props.data ? props.data.team : 'Loading'}
                </a>
              </li> */}
            <li>
              <a href='#contact' className='page-scroll'>
                {props.data ? props.data.contact : 'Loading'}
              </a>
            </li>

            <li>
              <Link to='/docs' className=''>
                {props.data ? props.data.docs : 'Loading'}
              </Link>
            </li>


            <li className="mydropdown" >

              <div className="dropdown" style={{ margin: '2px 0 0 20px' }}>
                <button
                  className="btn btn-secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true" style={{ marginTop: '-10px', marginRight: '25px' }}>
                  EN/TH
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton" style={{ width: '50px' }}>
                  <li style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="dropdown-item" onClick={() => { localStorage.setItem('lang', 'th'); localStorage.setItem('frombutton', 'true'); window.location.reload(); }} style={{ marginLeft: '25px' }}>TH</div>
                    <div className="dropdown-item" onClick={() => { localStorage.setItem('lang', 'en'); localStorage.setItem('frombutton', 'true'); window.location.reload(); }} style={{ marginLeft: '25px' }} >EN</div>
                  </li>
                </div>
              </div>
            </li>
          </ul>
        </div>

      </nav>

    </div>


  )
}


